import React from 'react';

function DebugDisplay ({ config, selectedDate, imageUrl, panStatus, loaded, showAlert }) {

  const { apiAuthKey, ...configWithoutAuthKey } = config;
  
  return (
    <div className="debugDisplay">
      <pre>Debug</pre>
      <pre>{JSON.stringify(configWithoutAuthKey, null, 2)} <br />selectedDate: {selectedDate}<br />imageUrl: {imageUrl}<br />
      {Object.entries(panStatus).map(([key, value]) => (
          <span key={key}>
            {key}: {value.toString()}<br />
          </span>
        ))}
        loaded: {loaded}<br />showAlert: {showAlert}</pre>
    </div>
  );
};

export default DebugDisplay;
