
export const renderDateControls  = ( onClick, label, period ) => {
  return (
      <div className="button-container">
      <span className="time-period-label">{label}</span>
      <div className="button-container-row">
        <button type="button" className="control-button previous-button" onClick={() => onClick("previous", period)}>&lt;</button>
        <button type="button" className="control-button next-button" onClick={() => onClick("next", period)}>&gt;</button>
      </div>
    </div>
  )
};
  
export const renderSpecialControls = ( onClick, selectedDate ) => {
  return (
     <div>
        <button type="button" className="control-button next-button" onClick={() => onClick(selectedDate, "xmas")}>Xmas</button>
        <button type="button" className="control-button next-button" onClick={() => onClick(selectedDate, "easter")}>Easter</button>
        <button type="button" className="control-button next-button" onClick={() => onClick(selectedDate, "whitMonday")}>Whit Monday</button>
        <button type="button" className="control-button next-button" onClick={() => onClick(selectedDate, "mayDay")}>May Day</button>
        <button type="button" className="control-button next-button" onClick={() => onClick(selectedDate, "autumn")}>Autumn</button>
        <button type="button" className="control-button next-button" onClick={() => onClick(selectedDate, "autumn6")}>Autumn+6</button>
        <button type="button" className="control-button next-button" onClick={() => onClick(selectedDate, "newYear")}>New Year</button>
        <button type="button" className="control-button next-button" onClick={() => onClick(selectedDate, "newYearsEve")}>NYE</button>
        <button type="button" className="control-button next-button" onClick={() => onClick(selectedDate, "halloween")}>Halloween</button>
        <button type="button" className="control-button next-button" onClick={() => onClick(selectedDate, "today")}>Today</button>
        <button type="button" className="control-button next-button" onClick={() => onClick(selectedDate, "add6wks")}>+6w</button>
        <button type="button" className="control-button next-button" onClick={() => onClick(selectedDate, "add6mns")}>+6m</button>
    </div>
  )
};


