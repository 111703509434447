// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import  ImageDisplay  from './ImageDisplay';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ImageDisplay  />} />
        <Route path="/:userDate" element={<ImageDisplay />} />
      </Routes>
    </Router>
  );
};

export default App;
