// ReportDialog.js
import React, { useState } from 'react';

const ReportDialog = ({ showReportDialog, handleReportClose, apiFeedbackUrl, apiAuthKey }) => {
  const [reportComment, setReportComment] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [reportResponseSubmitted, setReportResponseSubmitted] = useState(false);

  const sanitizeInput = (input) => {
    return input.replace(/[^a-zA-Z0-9\s]/g, '');
  };

  const handleReportSubmit = async (event) => {
    event.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!reportComment) {
      alert('Please enter a comment.');
      return;
    }

    if (!username) {
      alert('Please enter your name.');
      return;
    }

    if (email && !emailRegex.test(email)) {
      alert('Please enter a valid email address or leave blank.');
      return;
    }

    try {
      const response = await fetch(`${apiFeedbackUrl}`, {
        method: 'POST',
        headers: {
          'Authorization': `${apiAuthKey}`
        },
        body: JSON.stringify({
          "reportDate": "x",
          "username": sanitizeInput(username),
          "email": email,
          "url": window.location.href,
          "comment": sanitizeInput(reportComment)
        })
      });
      setReportResponseSubmitted(true);
      setTimeout(handleReportClose, 2000);
    } catch (error) {
      console.error('Error posting report:', error);
    }
  };

  return (
    showReportDialog && (
      <div className="dialog-right-overlay">
        <div className="dialog">
          <button className="close-btn" onClick={handleReportClose}>X</button>
          {!reportResponseSubmitted ? (
            <form onSubmit={handleReportSubmit}>
              <p className="prompt-text">Found an issue with this issue? Describe here.</p>
              <textarea
                value={reportComment}
                onChange={(e) => setReportComment(e.target.value)}
                placeholder="Enter your comment (max 255 chars)"
                maxLength="255"
              />
              <input
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter your name (max 50 chars)"
                maxLength="50"
              />
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email (optional)"
              />
              <button type="submit">Submit</button>
            </form>
          ) : (
            <p>Thank you for your feedback!</p>
          )}
        </div>
      </div>
    )
  );
};

export default ReportDialog;
