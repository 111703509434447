// ImageDisplay.js
import React, { useRef, useState, useEffect, useCallback  } from 'react';
import { useParams, useNavigate, json, useSearchParams  } from 'react-router-dom';
import { format, addDays, subDays, addWeeks, subWeeks, addMonths, subMonths, addYears, subYears } from 'date-fns';
import { isMobile, isTablet, isDesktop } from 'react-device-detect';

import './css/controls.css';
import './css/dialog.css';
import './css/ButtonPanel.css';

import FeedbackDialog from './components/FeedbackDialog';
import ReportDialog from './components/ReportDialog';
import DebugDisplay from './components/DebugDisplay';
import AboutDialog from './components/AboutDialog';
import Alert from './components/Alert';
import Clipboard from './components/Clipboard';
import DateStrap from './components/DateStrap';
import CalendarDialog from './components/Calendar';
import CalcMoment from './components/CalcMoment';
import { renderDateControls, renderSpecialControls } from './components/Controls';

import ImageContainer from './components/ImageContainer';

import config from './config.json';

const ImageDisplay = () => {
  // read from env.local or env vars
  const debug = process.env.REACT_APP_DEBUG_MODE;
  const apiAuthKey = process.env.REACT_APP_API_KEY;

  // ref vars
  const imageRef = useRef(null);
  const containerRef = useRef(null);

  // state vars
  // check if mobile
  const [isTouchDevice, setIsTouchDevice] = useState('ontouchstart' in document.documentElement);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1024);
  const [deviceType, setDeviceType] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [loaded, setLoaded] = useState(false);
  
  const triggerDivRef = useRef(null);
  const timeoutRef = useRef(null);

  const [panStatus, setPanStatus] = useState({
    scale: 1,
    startX: 0,
    startY: 0,
    translateX: 0,
    translateY: 0,
    isPanning: false
  });

  // show overlay dialogs
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [showAboutDialog, setShowAboutDialog] = useState(false);
  const [showReportDialog, setShowReportDialog] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  // read from config
  const [showNew, setShowNew] = useState(config.isNewVisible);

  const [copied, setCopied] = useState(false);

  // feedback fields
  const [feedbackComment, setFeedbackComment] = useState('');
  const [reportComment, setReportComment] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');

  // feedback state
  const [feedbackResponseSubmitted, setFeedbackResponseSubmitted] = useState(false);
  const [reportResponseSubmitted, setReportResponseSubmitted] = useState(false);

  const [menuVisible, setMenuVisible] = useState(false);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [isButtonVisible, setButtonVisibility] = useState(false);
  const targetDivRef = useRef(null);
  
  const detectDeviceType = () => {
    // Using react-device-detect
    let userAgentDeviceType = '';

    if (isMobile) {
      userAgentDeviceType = 'Mobile';
    } else if (isTablet) {
      userAgentDeviceType = 'Tablet';
    } else if (isDesktop) {
      userAgentDeviceType = 'Desktop';
    }

    const detectedDeviceType = `${userAgentDeviceType}`;
    setDeviceType(detectedDeviceType);
  };

  const handleEvent = async (action) => {
    if (! window.location.href.includes("localhost") ) {
      try {
        const width = window.innerWidth;
        const height = window.innerHeight;
        const response = await fetch(`${config.apiEventUrl}`, {
          method: 'POST',
          headers: {
            'Authorization': `${apiAuthKey}`
          },
          body: JSON.stringify({ // Convert object to JSON string
            "action": action,
            "viewport": `${width}x${height}`,
            "deviceType": `${deviceType}`,
            "url": window.location.href
          })
        });
      } catch (error) {
        // do nothing
        console.log(`Error posting event`, error);
      }
    }
  };

  const { userDate: paramDate } = useParams();
  //console.log(userDate);
  const [searchParams] = useSearchParams();
  const queryDate = searchParams.get('date');
  if (queryDate) { handleEvent(`Visit by shared link`);}
  // Prefer the query parameter if available, otherwise fallback to the URL parameter
  const inputDate = queryDate || paramDate || config.defaultDate;

  // the current date as YYYY-MM-DD string
  const [selectedDate, setSelectedDate] = useState(inputDate);

  // Function to check if the date is in the format YYYY-MM-DD  
  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date);
  };

  // Check if the incoming inputDate is a valid date
  if (!isValidDate(inputDate) || inputDate.length !== 10) {
    inputDate = config.defaultDate; // Set inputDate to config if it's not a valid date or doesn't match the length YYYY-MM-DD
  }


  
  // to change url on button
  const navigate = useNavigate();

  
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setButtonVisibility(false);
        } else {
          setButtonVisibility(true);
        }
      },
      {
        threshold: 0, // Trigger when the div is fully out of view
      }
    );

    if (targetDivRef.current) {
      observer.observe(targetDivRef.current);
    }

    return () => {
      if (targetDivRef.current) {
        observer.unobserve(targetDivRef.current);
      }
    };
  }, []);

  useEffect(() => {
    fetchData(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    detectDeviceType();
    window.addEventListener('resize', detectDeviceType);

    return () => {
      window.removeEventListener('resize', detectDeviceType);
    };
    }, []);




  // api call for issueUrl
  const fetchData = async (date) => {
    setLoaded(false);
    setImageUrl('');
    document.title = `UKArchiveTV: ${selectedDate}`;
    try {
      const response = await fetch(`${config.apiUrl}${date}`, {
        method: 'GET',
        headers: {
          'Authorization': `${apiAuthKey}`,
        },
      });
      const data = await response.json();
      const newImageUrl = data.issueUrl ? (data.issueUrl === "missing" ? `${config.missingUrl}` : data.issueUrl) : `${config.missingUrl}`;
      setImageUrl(newImageUrl);
      navigate(`/${date}`);
    } catch (error) {
      console.error('Error fetching picUrl:', error);
    }  
  };

  // Function to handle mouse down event for panning
  const handleMouseDown = (event) => {
    event.preventDefault();      
    setPanStatus(prevPanStatus => ({
      ...prevPanStatus,
      startX: event.clientX,
      startY: event.clientY,
      isPanning: true
    }));
  };

  // Function to handle mouse move event for panning
  const handleMouseMove = (event) => {
    if (panStatus.isPanning) {
      const deltaX = event.clientX - panStatus.startX;
      const deltaY = event.clientY - panStatus.startY;
  
      // Get container dimensions
      const containerWidth = containerRef.current.offsetWidth;
      const containerHeight = containerRef.current.offsetHeight;
  
      // Get image dimensions
      const imageWidth = imageRef.current.offsetWidth * panStatus.scale;
      const imageHeight = imageRef.current.offsetHeight * panStatus.scale;
  
      // Calculate maximum allowed translation
      const maxTranslateX = Math.max(0, (imageWidth - containerWidth) / 2);
      const maxTranslateY = Math.max(0, (imageHeight - containerHeight) / 2);
  
      setPanStatus(prevPanStatus => ({
        ...prevPanStatus,
        startX: event.clientX,
        startY: event.clientY,
        translateX: Math.min(maxTranslateX, Math.max(-maxTranslateX, panStatus.translateX + deltaX)),
        translateY: Math.min(maxTranslateY, Math.max(-maxTranslateY, panStatus.translateY + deltaY))
      }));
    }
  };

  // Function to handle mouse up event for panning
  const handleMouseUp = () => {
    setPanStatus(prevPanStatus => ({
      ...prevPanStatus,
      isPanning: false
    }));
  };

  // Functions for zoom buttons  
  const handleZoomIn = () => {
    // record Event
    handleEvent(`ZoomIn pressed`);
    setPanStatus(prevPanStatus => ({
      ...prevPanStatus,
      scale: (Math.min(config.maxScale, panStatus.scale + config.scaleStep))
    }));
  };

  const handleResetZoom = () => {
    // record Event
    handleEvent(`ZoomReset pressed`);
    setPanStatus(prevPanStatus => ({
      ...prevPanStatus,
      scale: 1,
      translateX: 0,
      translateY: 0,
      isPanning: false
    }));
  };

  const handleZoomOut = () => {
    // record Event
    handleEvent(`ZoomOut pressed`);    
    setPanStatus(prevPanStatus => ({
      ...prevPanStatus,
      scale: (Math.max(config.minScale, panStatus.scale - config.scaleStep))
    }));
  };
  
  useEffect(() => {
    if (imageRef.current) {
      imageRef.current.style.transform = `translate(${panStatus.translateX}px, ${panStatus.translateY}px) scale(${panStatus.scale})`;
    }
  }, [panStatus.scale, panStatus.translateX, panStatus.translateY]);

  const handleCopyLink = () => {
    // record event
    handleEvent(`Copy link pressed`);
    //navigator.clipboard.writeText(window.location.href)
    navigator.clipboard.writeText(window.location.origin + '/?date=' + selectedDate)
    .then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
       }, 5000);
    })
    .catch((error) => {
      console.error('Failed to copy:', error);
    });
  };

  // ensure other dialogs are closed if one is opened
  const handleFeedback = () => {
    // record event
    handleEvent(`Feedback pressed`);
    handleReportClose();
    handleAboutClose();
    setShowFeedbackDialog(true);
  };

  const handleReport = () => {
    // record event
    handleEvent(`Report pressed`);
    handleFeedbackClose();
    handleAboutClose();
    setShowReportDialog(true);
  };

  const handleAbout = () => {
    // record event
    handleEvent(`About pressed`);
    handleFeedbackClose();
    handleReportClose();
    setShowAboutDialog(true);
  };

  // close dialog and clear fields
  const handleFeedbackClose = () => {
    setShowFeedbackDialog(false);
    setFeedbackResponseSubmitted(false);
  };

  const handleReportClose = () => {
    setShowReportDialog(false);
    setReportResponseSubmitted(false);
  };

  const handleAboutClose = () => {
    setShowAboutDialog(false);
  };

  const toggleMenu = () => {
    // record event
    handleEvent(`toggleMenu pressed`);
    setMenuVisible(!menuVisible);
  };

  const toggleCalendar = () => {
    // record event
    handleEvent(`Calendar pressed`);
    setCalendarVisible(!calendarVisible);
  };

  function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth' // Smooth scroll to the top
    });
  }

  // Event listener to detect screen size changes
  window.addEventListener('resize', () => {
    setIsMobileView(window.innerWidth <= 1024);
  });

  const moveDate = useCallback((direction, period) => {
    setLoaded(false);
    //setImageUrl('');
    let newDate = new Date(selectedDate + ' 18:00:00');
    //console.log('newDate: ' + newDate);
    switch (period) {
      case "day":
        newDate = direction === 'next' ? addDays(newDate, 1) : subDays(newDate, 1);
        break;
      case "week":
        newDate = direction === 'next' ? addWeeks(newDate, 1) : subWeeks(newDate, 1);
        break;
      case "month":
        newDate = direction === 'next' ? addMonths(newDate, 1) : subMonths(newDate, 1);
        break;
      case "year":
        newDate = direction === 'next' ? addYears(newDate, 1) : subYears(newDate, 1);
        break;
      case "decade":
        newDate = direction === 'next' ? addYears(newDate, 10) : subYears(newDate, 10);
        break;
      default:
        break;
    }

    if (!(newDate >= new Date(config.lowerDateBound) && newDate <= new Date(config.upperDateBound))) {
      setShowAlert(true);
      setLoaded(true);      
      setTimeout(() => {
        setShowAlert(false);
       }, 5000);
    } else {
      setSelectedDate(newDate.toISOString().split('T')[0]);
    }
    setPanStatus(prevPanStatus => ({
      ...prevPanStatus,
      scale: 1,
      translateX: 0,
      translateY: 0,
      isPanning: false
    }));
    setMenuVisible(false);
  }, [selectedDate]);

  const moveDateSpecial = useCallback((newDate, specialMoment) => {
    // record Event
    handleEvent(`${specialMoment} pressed`);
    newDate = CalcMoment(new Date(selectedDate + ' 18:00:00'), specialMoment);
    
    if (!(newDate >= new Date(config.lowerDateBound) && newDate <= new Date(config.upperDateBound))) {
      setShowAlert(true);
      setLoaded(true);      
      setTimeout(() => {
        setShowAlert(false);
       }, 5000);
    } else {
      setSelectedDate(newDate.toISOString().split('T')[0]);
    }
    setPanStatus(prevPanStatus => ({
      ...prevPanStatus,
      scale: 1,
      translateX: 0,
      translateY: 0,
      isPanning: false
    }));
    setMenuVisible(false);
  }, [selectedDate]);

  const timer = setTimeout(() => {
    setShowNew(false);
  }, 7000); 

  return (   
    <div className="base">
    {/* Date controls */}
    {/* Show sliding menu toggle button on mobile */}
    {isMobileView && (
      <div className={`controls-menu ${menuVisible ? 'show-menu' : ''}`}>
      {/* Special controls */}
      <div className="controls-wrapper">
      <div className="button-container">
        {renderSpecialControls(moveDateSpecial, selectedDate)}    
      </div>  
      </div>
    </div>
    )}
    <div className="container">
    
    <DateStrap 
      selectedDate={selectedDate}
      handleAbout={handleAbout}
      menuVisible={menuVisible}
      toggleMenu={toggleMenu}
      isMobileView={isMobileView}
      showNew={showNew}
      toggleCalendar={toggleCalendar}
      calendarVisible={calendarVisible}
      handleCopyLink={handleCopyLink}
      handleFeedback={handleFeedback}
      handleReport={handleReport}
    />
    <CalendarDialog
        calendarVisible={calendarVisible}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        lowerDateBound={config.lowerDateBound}
        upperDateBound={config.upperDateBound}
    />
    { debug == "true" ? 
    <DebugDisplay 
        config={config} 
        selectedDate={selectedDate} 
        scale={panStatus.scale} 
        imageUrl={imageUrl} 
        translateX={panStatus.translateX} 
        translateY={panStatus.translateY} 
        panStatus={panStatus} 
        loaded={loaded} 
        showAlert={showAlert} 
    /> : null}
    {<div ref={targetDivRef} className="controls-wrapper row">
      {renderDateControls(moveDate, 'Decade', 'decade')}
      {renderDateControls(moveDate, 'Year', 'year')}
      {renderDateControls(moveDate, 'Month', 'month')}
      {renderDateControls(moveDate, 'Week', 'week')}
      {renderDateControls(moveDate, 'Day', 'day')}
    </div>}

    {!isMobileView && (
    <div className="controls-wrapper row">
      <div className="button-container row">
        {renderSpecialControls(moveDateSpecial, selectedDate)}    
      </div>
    </div>
    )}

    {isButtonVisible && ! isMobileView && (
        <div className="menu-button">
          <div className="hamburger-lines"></div>
          <div className="button-panel">
            <div className="button-panel-date">{format(new Date(selectedDate), 'EEE MMMM d, yyyy')}</div>
            <div className="controls-wrapper row">
                {renderDateControls(moveDate, 'Decade', 'decade')}
                {renderDateControls(moveDate, 'Year', 'year')}
                {renderDateControls(moveDate, 'Month', 'month')}
                {renderDateControls(moveDate, 'Week', 'week')}
                {renderDateControls(moveDate, 'Day', 'day')}
                <button className="control-button" onClick={scrollToTop}>↑</button>
            </div>
        </div>
      </div>
    )}
    

    <FeedbackDialog 
      showFeedbackDialog={showFeedbackDialog} 
      handleFeedbackClose={handleFeedbackClose}
      feedbackResponseSubmitted={feedbackResponseSubmitted}
      apiFeedbackUrl={config.apiFeedbackUrl}
      apiAuthKey={apiAuthKey}
    />
    <ReportDialog 
      showReportDialog={showReportDialog} 
      handleReportClose={handleReportClose} 
      apiFeedbackUrl={config.apiFeedbackUrl}
      apiAuthKey={apiAuthKey}
    />
    <AboutDialog 
      showAboutDialog={showAboutDialog} 
      handleAboutClose={handleAboutClose}
      aboutUrl={config.aboutUrl}
    />
    <Alert showMessage={showAlert} />
    <Clipboard showMessage={copied} />
    <ImageContainer 
        handleMouseMove={handleMouseMove}
        handleMouseUp={handleMouseUp}
        handleMouseDown={handleMouseDown}
        handleZoomIn={handleZoomIn}
        handleResetZoom={handleResetZoom}
        handleZoomOut={handleZoomOut}
        isTouchDevice={isTouchDevice}
        loaded={loaded}
        setLoaded={setLoaded}
        containerRef={containerRef}
        imageRef={imageRef}
        imageUrl={imageUrl}
        selectedDate={selectedDate}
        triggerDivRef={triggerDivRef}
    />
  </div>
  </div>
  );
};

export default ImageDisplay;
