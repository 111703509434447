import React, { useState, useEffect } from 'react';
import '../css/Alert.css'; // Import CSS for styling the alert

const Alert = ({ showMessage }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (showMessage) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
      }, 5000); // Hide after 5 seconds

      return () => clearTimeout(timer);
    } else {
        setVisible(false);
    }
  }, [showMessage]);

  return (
    <div className={`alert ${visible ? 'show' : ''}`}>
      Out of range (1923-1995)
    </div>
  );
};

export default Alert;
