import React, { useState } from 'react';

function ImageContainer({ handleMouseMove, handleMouseUp, handleMouseDown, handleZoomIn, 
        handleResetZoom, handleZoomOut, isTouchDevice, loaded, setLoaded, containerRef, imageRef, imageUrl, selectedDate, triggerDivRef}) {     

    return (
        <div className="image-container" onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} onMouseDown={handleMouseDown} onMouseLeave={handleMouseUp}>
            
            <div className="zoom-buttons">
                {!isTouchDevice && loaded && ( // Only show buttons on desktop after image is loaded
                <>
                    <button className="zoom-button" onClick={handleZoomIn}>
                    <span>+</span>
                    </button>
                    <button className="zoom-button" onClick={handleResetZoom}>
                    <span>&#183;</span>
                    </button>
                    <button className="zoom-button" onClick={handleZoomOut}>
                    <span>-</span>
                    </button>
                </>
                )}
            </div>
            <div className="outer-image-container" ref={containerRef}>
            {
                loaded ? null :
                <img src='squares.svg' height={100} width={200}
                alt={`TVL`} />
            } 
            <img ref={imageRef}
                src={imageUrl} 
                alt={`UKTV Listing ` + selectedDate} 
                style={loaded ? { 
                    display: 'block', 
                    maxWidth: '100%', 
                    maxHeight: '100%' } : { display: 'none' }}
                onLoad={() => setLoaded(true)}
            />
            </div>
        </div>
    )

}

export default ImageContainer;