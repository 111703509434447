import React, { useState, useEffect } from 'react';
import axios from 'axios'; // You may need to install axios using npm or yarn

function AboutDialog({ showAboutDialog, handleAboutClose, aboutUrl }) {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    async function fetchHTML() {
      try {
        const response = await axios.get(aboutUrl);
        setHtmlContent(response.data);
      } catch (error) {
        console.error('Error fetching HTML:', error);
      }
    }

    fetchHTML();
  }, []);

  return (
    showAboutDialog && (
    <div className="dialog-left-overlay">
        <div className="dialog">
        <button className="close-btn" onClick={handleAboutClose}>X</button>
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </div>
    </div>
    )
  );
}

// <span className="version">{process.env.REACT_APP_BUILD_NUMBER}</span>

export default AboutDialog;
